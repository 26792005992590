$rotationDuration: 20s;
$hoverDuration: 0.3s;
$mobile: 768px;
$tablet: 1024px;

$easeIn: cubic-bezier(0.32, 0, 0.67, 0);
$easeOut: cubic-bezier(0.33, 1, 0.68, 1);

$textColor: #2b3338;
$bgColor: #a6b8b1;
$accentColor: #e8d6c1;

#AppRotateButton {
  .desktop {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  .mobile{
    display: none;
    @media (max-width: $mobile) {
      display: block;
    }
  }

  .link {
    width: 10rem;
    height: 10rem;
    display: inline-block;
    font: 300 1.55rem/1.4 "Josefin Sans";
    text-transform: uppercase;
    letter-spacing: 0.1175em;
    word-spacing: 0.3em;
    text-decoration: none;

    &__svg {
      width: 100%;
      height: auto;
      transform-box: fill-box;
      fill: $textColor;
      stroke: $textColor;
      stroke-width: 0.05em;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    &__cloud {
      transform-origin: 50% 50%;
      animation: rotate normal infinite 3 * $rotationDuration linear;
      fill: rgba(255, 255, 255, 0.15);
    }

    &__face,
    &__arrow {
      transform-origin: 50% 50%;
      transition: transform $hoverDuration / 2 $easeIn;

      .link:hover & {
        transform: scale(1.1);
        transition: transform $hoverDuration $easeOut;
      }
    }

    &__arrow {
      stroke-width: 0.075em;
    }

    &__text {
      animation: rotateReverse normal infinite $rotationDuration linear;
      transform-origin: 50% 50%;

      .link:hover & {
        animation-play-state: paused;
      }
    }

    &--alt {
      font-size: 1.15rem;
      letter-spacing: 0;
      word-spacing: 0;
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotateReverse {
    to {
      transform: rotate(-360deg);
    }
  }
}
