@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
$mobile: 768px;
$tablet: 1024px;

html {
  scroll-behavior: smooth;
}

* {
  transition: all 0.05s ease;
  box-sizing: border-box;
}

a {
  text-decoration: none; //btn
}

body {
  margin: 0;
  font-family: Montserrat;
  font-size: 20px;
  line-height: 22px;

  color: black;

  // background: #8faadc;
  // background-image: url('imgs/pattern.svg');
  // background-size: cover;
  // background-repeat: repeat;
  //   background: linear-gradient(
  //     #8faadc
  //     #8faadc 0%,   /* Soft blue */
  //     #6d88b3 22%,  /* Medium blue */
  //     #5a99a9 49%,  /* Muted teal */
  //     #d1a8a5 78%,  /* Light muted coral */
  //     #7893a3 100%  /* Soft steel blue */
  // );

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

a {
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0em;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 0.01rem;
  display: block;
}

h1 {
  font-size: 2.3rem;

  @media (max-width: $tablet) {
    font-size: 2rem;
    margin-top: 0;
  }
}

h2 {
  font-size: 3rem;
  margin: 0 5px;
}

h3 {
  font-size: 2.5rem;
}

h4 {
  font-size: 2rem;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w200 {
  font-weight: 200;
}

.icon.big {
  font-size: 10rem;
}

.home {
  margin: 0 auto;
}

// the box
.box {
  padding: 2% 5%;
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: $mobile) {
    padding: 10px;
  }
}

.first-section-color {
  background-image: url("imgs/pattern1.svg");
  background-color: #b564f7;
  // background-color: #7449d2;
  background-repeat: repeat;

  background-size: 100%;
  background-position-x: 0px;
  background-position-y: 180px;
  .firstVideo {
    position: absolute;
    height: 458px;
    width: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .first-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
    color: white;
    font-weight: bolder;
    height: 458px;

    h1 {
      @media (max-width: $mobile) {
        font-size: 23px;
      }
    }

    .btn {
      padding-right: 50px;
      @media (max-width: $mobile) {
        margin: 0 auto;
        padding-right: 0;
      }
    }
  }
}

.header-black {
  background-image: url("imgs/pattern.svg");
  background-color: #2f0e3b;
  background-size: cover;
  background-repeat: repeat;
  background-position-x: 300px;

  .main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile) {
      display: flex;
      min-height: 50px;
    }

    .mobile-menu-toggle {
      display: none;
      @media (max-width: $mobile) {
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;
        border-radius: 5px;
        background-color: black;
        color: white;
        border: none;
      }
    }

    .title {
      font-weight: 600;
      font-size: calc((1) * 1.2vw + 1rem);
      margin: 0;
      color: white;
    }

    nav {
      transition: 0.3s;
      z-index: 10;

      @media (max-width: $mobile) {
        display: block;
        margin-top: 0;
        position: absolute;
        right: 0px;
        top: 66px;
        opacity: 0;
        width: 100%;
        display: none;

        &.open {
          opacity: 1;
          display: block;
        }
      }

      a {
        color: white;
        text-decoration: none;
        margin: 2px;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 18px;
        border-bottom: 1px solid transparent;
        transition: .5s;
        padding-bottom: 10px;

        &:hover{
          border-bottom: 1px solid white;
        }

        @media (max-width: $mobile) {
          grid-template-columns: 1fr;
          display: block;
          text-align: center;
          min-width: 200px;
          border-radius: 0;
          padding: 15px;
          background: black;
          margin: 0;
        }
      }
    }
  }
}

p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 0 3px;
}

.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.gap-10 {
  gap: 10px;
}

.first-text-width {
  max-width: 60%;

  h1 {
    font-size: 38px;
    font-weight: bolder;
  }
  h2 {
    font-size: 30px;
    @media (max-width: $mobile) {
      font-size: 22px;
    }
  }
  h1,
  h2 {
    text-shadow: 1px 1px 3px black;
  }
  @media (max-width: $mobile) {
    max-width: 100%;
  }
}

.mt-100 {
  margin-top: 100px;
}

#idea-section {
}

.image-style-hart {
  clip-path: polygon(
    30% 0%,
    50% 15%,
    70% 0%,
    90% 10%,
    100% 35%,
    80% 70%,
    50% 100%,
    20% 70%,
    0% 35%,
    10% 10%
  );
  width: 390px;
  height: 390px;
  object-fit: cover;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#brand-img {
  width: 100%;
  border-radius: 60px;
  margin: 0 auto;
}

.learn-more-link-1 {
  margin-top: 20px;
  display: block;
}

.text-center {
  text-align: center;
}

.image-style-flower {
  display: block;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  width: 250px;
  margin-top: 50px;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.gray {
  filter: grayscale(100%);
}

.align-items-center {
  align-items: center;
}

#what-we-do {
  .content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
  }
}

footer {
  background-color: black;
  color: white;
  padding: 50px;
  font-size: 0.9rem;

  .footer-text-wrap {
    display: flex;
    justify-content: space-between;
    @media (max-width: $mobile) {
      display: flex;
      flex-wrap: wrap;
    }

    .social-txt {
      margin-bottom: 0;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.box-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: $tablet) {
    grid-template-columns: 1fr 1fr;
    padding: 10px;
  }

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }

  .box {
    padding: 15px;
    border-radius: 5px;
    display: grid;
    transition: 0.3s;
    background: white;
    box-shadow: 0px 1px 10px #1b0e39;
  }
  .box:hover {
    background: linear-gradient(
      180deg,
      rgba(157, 223, 241, 1) 0%,
      rgba(188, 128, 249, 1) 100%
    );
    // border: 3px solid black;
    cursor: pointer;
    font-weight: bold;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 36px;
    @media (max-width: $mobile) {
      min-height: auto;
      padding: 0;
      text-align: center;
    }
  }

  h4 {
    font-size: 20px;
    display: inline-block;
    min-height: 60px;
    font-weight: bold;

    @media (max-width: $mobile) {
      min-height: auto;
      padding: 0;
      text-align: center;
    }
  }

  .hashtags {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;

    div {
      // background: linear-gradient(180deg, #b564f7, #744fc6 22%, #4fb0c6 49%, #f9b5ac 78%, #4f86c6);
      font-weight: bold;
      line-height: 5px;
      margin-right: 5px;
      padding: 5px;
      border-radius: 5px;
      margin-top: 5px;
    }
  }
}

section.faq {
  padding: 10px;
  // background-image: url("imgs/pattern2.svg");
  background-color: #8059d4;
  // background-size: cover;
  // background-repeat: repeat;

  // background-size: 124% auto;
  // background-position-x: -454px;

  > .box {
    padding-bottom: 5px;
    > h2 {
      color: white;
      font-weight: bold;
    }
  }

  .faq-box-section {
    .box {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 10px;
      margin-bottom: 10px;
      box-shadow: 0px 1px 3px #b564f7;
      border-radius: 3px;

      &.open-wrapper {
        background: #2d1045;
        color: white;
        font-weight: bold;
      }
    }

    h4 {
      margin: 0;
      cursor: pointer;
      font-weight: 500;
      font-size: 23px;
      line-height: 26px;
      position: relative;
      padding-right: 35px;
    }

    .open,
    .close {
      position: absolute;
      right: 0;
      top: -12px;
      font-size: 50px;
      font-weight: bold;
      display: none;
    }

    .close {
      display: block;
    }

    h4.active .open {
      display: block;
    }

    h4.active .close {
      display: none;
    }

    p {
      font-size: 18px;
    }
  }
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
}

section#compare {
  padding-bottom: 0px;
  background: black;
  color: white;

  .box {
    padding-bottom: 0px;
    padding-top: 10px;
  }

  .other-us {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    position: relative;
    padding-bottom: 50px;

    h3 {
      margin: 0;
    }
    @media (max-width: $mobile) {
      grid-template-columns: 1fr;

      h3 {
        padding: 0;
      }
    }

    .vs {
      width: 70px;
      height: 70px;
      display: block;
      margin: 0 auto;
      left: calc(50% - 35px);
      top: calc(50% - 35px);
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: flex;
      color: white;
      background: black;
      font-weight: 500;
      position: absolute;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    .other,
    .us {
      padding: 30px;
      padding-left: 54px;

      @media (max-width: $mobile) {
        padding-left: 10px;
      }
    }

    .other {
      background-color: #232323;
    }

    .us {
      background-color: #744fc6;
      font-weight: bold;

      h3 {
        font-weight: bold;
      }
    }
  }
}

.pricing-bg {
  background-image: url("imgs/pattern1.svg");
  background-size: cover;
  background-position-x: 150px;
  background-repeat: repeat;
  // background-color: #b564f7;
  background-color: #7449d2;
}
section.pricing {
  border-bottom: 7px solid #8a6fdd42;
  padding-bottom: 60px;
  color: white;

  .pricing {
    font-size: 16px;
    font-style: italic;
  }

  .p-box-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 10px;
    grid-gap: 10px;

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }

    h2 {
      margin: 0;
      margin-bottom: 5px;
      font-weight: bold;
    }

    > div {
      background-color: #292929;
      padding: 20px;
      color: white;
      box-shadow: 0px 0px 3px 1px #008080;
    }
  }

  ul {
    margin: 0;
    padding-left: 23px;
  }

  ul > li {
    margin: 0;
    padding-top: 12px;
    letter-spacing: normal;
    font-size: 18px;
  }
}

// second page
.start {
  .email-bg {
    background-image: url("imgs/pattern1.svg");
    background-size: cover;
    background-position-x: 150px;
    background-repeat: repeat;
    background-color: #7449d2;
  }

  .email-box {
    padding: 7% 5%;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;

    @media (max-width: $mobile) {
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr;
    }
  }

  .input-wrap {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    align-items: center;
  }

  .txt-email {
    padding: 20px 30px;
    border-radius: 30px;
    border: none;
    width: 100%;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  label {
    font-weight: 500;
  }
}

.about-us {
  padding-bottom: 0;
}

#about {
  background: black;
  color: white;
  font-weight: bold;
  padding-bottom: 30px;
}

.idea-bg {
  background: aliceblue;
}
#idea-section {
  padding-top: 0;
}
